

import comingsoon1 from '../../assest/AayatTelecom/iphone/iphone16.jpeg'
import comingsoon2 from '../../assest/AayatTelecom/iphone/iphone16pro.jpg'
import comingsoon3 from '../../assest/AayatTelecom/iphone/IPHONE16promax.webp'
import comingsoon4 from '../../assest/AayatTelecom/iphone/iphone 15.png'
import comingsoon5 from '../../assest/AayatTelecom/iphone/iphone-15-pro.png'
import comingsoon6 from '../../assest/AayatTelecom/iphone/IPHONE15promax.jpg'
import comingsoon7 from '../../assest/AayatTelecom/Samsung/GALAXY FLIP 6.webp'
import comingsoon8 from '../../assest/AayatTelecom/Samsung/GALAXY FOLD 6.png'
import comingsoon9 from '../../assest/AayatTelecom/Samsung/SAMSUNG A055.jpg'
import comingsoon10 from '../../assest/AayatTelecom/Samsung/SAMSUNG A057.jpg'
import comingsoon11 from '../../assest/AayatTelecom/Samsung/SAMSUNG A155.png'
import comingsoon12 from '../../assest/AayatTelecom/Samsung/SAMSUNG A256.webp'
import comingsoon13 from '../../assest/AayatTelecom/Samsung/SAMSUNG A356.webp'
import comingsoon14 from '../../assest/AayatTelecom/Samsung/SAMSUNG A556.webp'
import comingsoon15 from '../../assest/AayatTelecom/Samsung/SASMUNG S24 ULTRA.webp'
import comingsoon16 from '../../assest/AayatTelecom/Xiaomi/POCO C65.jpg'
import comingsoon17 from '../../assest/AayatTelecom/Xiaomi/REDMI 13 PRO.jpg'
import comingsoon18 from '../../assest/AayatTelecom/Xiaomi/REDMI 13.webp'
import comingsoon19 from '../../assest/AayatTelecom/Xiaomi/REDMI 13.webp'
import comingsoon20 from '../../assest/AayatTelecom/Xiaomi/REDMI 13C.jpg'
import comingsoon21 from '../../assest/AayatTelecom/Xiaomi/REDMI A3.webp'
import comingsoon22 from '../../assest/AayatTelecom/Techno/Spark Go 1.png'
import comingsoon23 from '../../assest/AayatTelecom/Techno/Spark Go.jpg'
import comingsoon24 from '../../assest/AayatTelecom/Techno/TECNO CAMON 20.webp'
import comingsoon25 from '../../assest/AayatTelecom/Techno/TECNO POVA 6.png'
import comingsoon26 from '../../assest/AayatTelecom/Infinix/HOT 40 PRO.webp'
import comingsoon27 from '../../assest/AayatTelecom/Infinix/HOT 40I.jpg'
import comingsoon28 from '../../assest/AayatTelecom/Infinix/NOTE 40X 5G.jpg'





const GallerData = [
    
    {
        id: 3,
        src: comingsoon3,
    }, {
        id: 4,
        src: comingsoon4,
    }, {
        id: 5,
        src: comingsoon5,
    }, {
        id: 6,
        src: comingsoon6,
    },
    {
        id: 7,
        src: comingsoon7,
    },
    {
        id: 8,
        src: comingsoon8,
    },
    {
        id: 9,
        src: comingsoon9,
    },
    {
        id: 10,
        src: comingsoon10,
    },
    {
        id: 11,
        src: comingsoon11,
    },
    {
        id: 12,
        src: comingsoon12,
    },
    
    {
        id: 14,
        src: comingsoon14,
    },
    {
        id: 15,
        src: comingsoon15,
    },
    {
        id: 16,
        src: comingsoon16,
    },
    {
        id: 17,
        src: comingsoon17,
    },
    {
        id: 18,
        src: comingsoon18,
    },
    {
        id: 19,
        src: comingsoon19,
    },
    {
        id: 20,
        src: comingsoon20,
    },
    {
        id: 21,
        src: comingsoon21,
    },
    
    {
        id: 22,
        src: comingsoon22,
    },
    
    {
        id: 23,
        src: comingsoon23,
    },
    {
        id: 24,
        src: comingsoon24,
    },
    {
        id: 25,
        src: comingsoon25,
    },
    {
        id: 26,
        src: comingsoon26,
    },
    {
        id: 27,
        src: comingsoon27,
    },
    {
        id: 28,
        src: comingsoon28,
    }

];
export default GallerData;