import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom"
import polygon from '../../assest/AayatTelecom/blogsbanner.jpg'
import '../../styles/AayatTelecom/blogStyle.css'
import BlogsData from "../../data/AayatTelecom/BlogsData"

const Blogs = () => {
    return (
        <>
            <div>
                <Box className='Travelogue_topdiv'>
                    <img src={polygon} alt="img" />
                    <div className="traveldiv_head">
                        <h1>Blogs</h1>
                    </div>
                </Box>
            </div>

            <div className="Travelogue_div">
                <div className="travelogue_slide">
                    {BlogsData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="travelogue_slidedetail">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h3>07 May 2024</h3>
                                <h4>{item.heading}</h4>
                                <p>Posted by : {item.Postedby}</p>
                                <p>{item.textdetail}</p>
                                <Button className="booknowbtn" fullWidth>Read More</Button>

                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="butnn">Explore More</Button> */}
            </div >

        </>
    )
}

export default Blogs