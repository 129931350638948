

import people1 from '../../assest/AayatTelecom/secure.svg'
import people2 from '../../assest/AayatTelecom/calendar.webp'
import people3 from '../../assest/AayatTelecom/download1.png'




const AboutExpertiseData = [
    {
        id: 1,
        heading: "20+",
        textdetail: "Products",
        imgSrc: people1,
    },
    {
        id: 2,
        heading: "15",
        textdetail: "Years of Experience",
        imgSrc: people2,
    },
    {
        id: 3,
        heading: "200+",
        textdetail: "Clients.",
        imgSrc: people3,
    },
    
];
export default AboutExpertiseData