import people1 from '../../assest/AayatTelecom/visualhunter.png'
import people2 from '../../assest/AayatTelecom/ipad-1.png'
import people3 from '../../assest/AayatTelecom/full-hd.png'
import people4 from '../../assest/AayatTelecom/visualhunter.png'
import people5 from '../../assest/AayatTelecom/ipad-1.png'
import people6 from '../../assest/AayatTelecom/full-hd.png'
import people7 from '../../assest/AayatTelecom/visualhunter.png'
import people8 from '../../assest/AayatTelecom/ipad-1.png'




const BlogsData = [
    {
        id: 1,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people2,
    },

    {
        id: 3,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people5,
    },
     {
        id: 5,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people6,
    }, {
        id: 6,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people4,
    }
    , {
        id: 7,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people7,
    }, {
        id: 8,
        heading:"Why Should we Buy a iPhone in 2024-25?",
        Postedby:"Anirban Bose",
        textdetail:'There are so many mobile brands in this world, but why do we choose the iPhone? Is the iPhone cheap in Price? We have a lot of doubts. So why should we buy a used iPhone in 2022?  Anyway, let us first check a little about the history of the Apple Brand, On April 1, 1976, college dropouts Steve Jobs and Steve Wozniak joined Apple Computers, Inc.',
        imgSrc: people8,
    }
   
];
export default BlogsData