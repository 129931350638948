import featured1 from '../../assest/AayatTelecom/iphone/iphone16.jpeg'
import featured2 from '../../assest/AayatTelecom/iphone/iphone16pro.jpg'
import featured3 from '../../assest/AayatTelecom/iphone/IPHONE16promax.webp'
import featured4 from '../../assest/AayatTelecom/iphone/iphone-15.jpg'
import featured5 from '../../assest/AayatTelecom/iphone/iphone-15-pro.png'
import featured6 from '../../assest/AayatTelecom/iphone/IPHONE15promax.jpg'
import featured7 from '../../assest/AayatTelecom/iphone/iphone-14.webp'
import featured8 from '../../assest/AayatTelecom/iphone/iphone14pro.jpg'
import featured9 from '../../assest/AayatTelecom/iphone/iphone14promax.webp'
import featured10 from '../../assest/AayatTelecom/iphone/IPHONE13.jpeg'
import featured11 from '../../assest/AayatTelecom/iphone/IPHONE13PRO.webp'
import featured12 from '../../assest/AayatTelecom/iphone/IPHONe13PROMAX.jpeg'
import featured13 from '../../assest/AayatTelecom/iphone/IPHONE12.webp'
import featured14 from '../../assest/AayatTelecom/iphone/IPHONE12PRO.webp'
import featured15 from '../../assest/AayatTelecom/iphone/IPHONE12PROMAX.webp'
import featured16 from '../../assest/AayatTelecom/iphone/IPHONE11.jpg'
import featured17 from '../../assest/AayatTelecom/iphone/IPHONE11PRO.webp'
import featured18 from '../../assest/AayatTelecom/iphone/IPHONE11promax.jpg'




const AllIphoneProductData = [
    {
        id: 1,
        companyName:'IPHONE 16',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'IPHONE 16 PRO',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'IPHONE 16 PRO MAX',
        imgSrc: featured3,
    }, 
    {
        id: 4,
        companyName:'IPHONE 15',
        imgSrc: featured4,
    },
     {
        id: 5,
        companyName:'IPHONE 15 PRO',
        imgSrc: featured5,
    }, 
    {
        id: 6,
        companyName:'IPHONE 15 PRO MAX ',
        imgSrc: featured6,
    },
    // {
    //     id: 7,
    //     companyName:'IPHONE 14',
    //     imgSrc: featured1,
    // },
    //  {
    //     id: 8,
    //     companyName:'IPHONE 14 PRO',
    //     imgSrc: featured8,
    // }, 
    // {
    //     id: 9,
    //     companyName:'IPHONE 14 PRO MAX ',
    //     imgSrc: featured9,
    // },
    // {
    //     id: 10,
    //     companyName:'IPHONE 13',
    //     imgSrc: featured10,
    // },
    //  {
    //     id: 11,
    //     companyName:'IPHONE 13 PRO',
    //     imgSrc: featured11,
    // }, 
    // {
    //     id: 12,
    //     companyName:'IPHONE 13 PRO MAX ',
    //     imgSrc: featured12,
    // },
    // {
    //     id: 13,
    //     companyName:'IPHONE 12',
    //     imgSrc: featured13,
    // },
    //  {
    //     id: 14,
    //     companyName:'IPHONE 12 PRO',
    //     imgSrc: featured14,
    // }, 
    // {
    //     id: 15,
    //     companyName:'IPHONE 12 PRO MAX ',
    //     imgSrc: featured15,
    // },
    // {
    //     id: 16,
    //     companyName:'IPHONE 11',
    //     imgSrc: featured16,
    // },
    //  {
    //     id: 17,
    //     companyName:'IPHONE 11 PRO',
    //     imgSrc: featured17,
    // }, 
    // {
    //     id: 18,
    //     companyName:'IPHONE 11 PRO MAX ',
    //     imgSrc: featured18,
    // }
];
export default AllIphoneProductData