import React, { useState } from 'react'
import Logo from "../../../assest/AayatTelecom/logo.png";
import { Box, Button, Drawer } from '@mui/material';
import { MdOutlineMenu } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './HeaderStyle.css'
import SideBar from '../sidebar/SideBar';


const Header = () => {
    const [navActive, setNavActive] = useState("#");

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };


    return (
        <>
            <Box className="tanishka_topHeader">
            <div className='tanishkadiv_mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>

                <Link to="/"
                    className={navActive === "/" ? "active" : ""}
                    onClick={() => setNavActive("/")}
                >
                    <img className="logo" src={Logo} alt='Logo' />
                    <span className='ngo_compnyName'> AAYAT TELECOM FZCO</span>
                </Link>


                <Box className='rightbox_taniska'>
                    <ul className="tanishka_liauto">
                        

                        <li>
                            <Link
                                to="/"
                                onClick={() => setNavActive("/")}
                                className={navActive === "/" ? "active" : ""}
                            >
                                Home
                            </Link>
                        </li>

                       

                        <li>
                            <Link
                                to="/about_us"
                                onClick={() => setNavActive("/about_us")}
                                className={navActive === "/about_us" ? "active" : ""}
                            >
                                About us
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/services"
                                onClick={() => setNavActive("/services")}
                                className={navActive === "/services" ? "active" : ""}
                            >
                                Services
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/photo_gallery"
                                onClick={() => setNavActive("/photo_gallery")}
                                className={navActive === "/photo_gallery" ? "active" : ""}
                            >
                                Photo Gallery
                            </Link>
                        </li>
        
                        <li>
                            <Link
                                onClick={() => setNavActive("/blogs")}
                                className={navActive === "/blogs" ? "active" : ""}
                                to="/blogs"
                            >
                                Blog
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/contact_us")}
                                className={navActive === "/contact_us" ? "active" : ""}
                                to="/contact_us"
                            >
                                Contact
                            </Link>
                        </li>

                    </ul>
                </Box>
            </Box>
        </>
    )
}

export default Header