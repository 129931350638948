const FaqData = [
  {
    id: 1,
    title: " How do I become a Aayat Telecom customer ?",
    details:
      "Contact the company representative at +971563488541 , or email us at info@abaris.com . We are happy to discuss your needs. Make sure to include the phone number you can call (phone or WhatsApp), as well as some information about the product (such as mobile phones, tablets, accessories, etc.) and the conditions you are most interested in. You need to provide some documents (see “What information do I need to open an account?”). After completing this operation and your application will be processed within one working day.",
  },
  {
    id: 2,
    title: "  What is your minimum order quantity ?",
    details:
      "For new customers, the minimum quantity you can purchase from Aayat Telecom for the first time is 25 pieces. So the minimum order quantity for future orders is 10,000 USD (50 pieces). For more detailed information about our inventory, please explore our website or contact info@abaris.com for the latest inventory list.",
  },
  {
    id: 3,
    title: " What warranty terms do you provide ?",
    details:
      "Our warranty period for used smartphones and tablets is 60 days, covering only functions. For brand new devices and wearable devices (such as smart watches), the warranty period is 30 days. There is 30 days replacement warranty for batteries and accessories. And also the warranty starts from the invoice date.",
  },

  {
    id: 4,
    title: " What do COB / CPO / Brown Box mean ?",
    details:
      "The COB (Certified Open Box) or brown box iPad is a fully tested and fully functional second-hand device with minimal wear and tear. COB refers to the equipment sold in the manufacturer’s brand packaging, and the Brown Box means that the equipment has been packaged in an ordinary brown box. Apple CPO (Certified Used) devices are fully tested and functional devices that have been thoroughly cleaned and inspected. They are equipped with all accessories and have a 1-year warranty from Apple. All equipment is in a white box. ",
  },
  {
    id: 5,
    title: " What information do you need to open an account ?",
    details:
      "U.S. distributors need to provide the resale certificate granted to the state and a copy of their valid sales tax license or authorization certificate from your state. If you are an overseas distributor, we will need a copy of your ID card, as well as a copy of company documents confirming ownership and billing information. You can fill out the application before submitting the documents, but your application will not be processed until all the documents are provided.",
  },
  {
    id: 6,
    title: " How do I make payments ?",
    details:
      "All payments must be made by wire transfer and also Your sales representative will provide you with this information when you make your first purchase.",
  },
  {
    id: 7,
    title: " Shipping : how does it work ?",
    details:
      "You can choose to use your own or Aayat Telecom shipping method. If you use your own, you need to provide your account manager with the name of your account and carrier. and also the inventory will be shipped within 1-2 business days after the sales order is approved.",
  },
  {
    id: 8,
    title: " What inventory do you have in stock ?",
    details:
      "Our inventory changes daily and includes a wide range of Android and iOS mobile devices, as well as tablets, accessories, Apple watches and more.  Get in touch with info@abaris.com to get our latest inventory list.",
  },
];

export default FaqData;
