import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Rating } from '@mui/material';
import { FaCircleRight } from "react-icons/fa6";
import '../styles/AayatTelecom/mobileCardStyle.css';



const MobileCard = ({ title, detailsData }) => {

    return (
        <>
            <div className="FeaturedProject_slide">
                {detailsData?.map((item, index) => (
                    <div className="project_fetaureddiv">
                        <div>
                            <img src={item.imgSrc} alt="img" />
                        </div>
                        <div>
                            <h6>{item.companyName}</h6>
                        </div>                        
                    </div>

                ))}
            </div >
        </>
    )
}

export default MobileCard
