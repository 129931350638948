import featured1 from '../../assest/AayatTelecom/mac/MacBook Air (15-inch, M2).jpg'
import featured2 from '../../assest/AayatTelecom/mac/MacBook Air (13-inch, M2).jpg'
import featured3 from '../../assest/AayatTelecom/mac/MacBook Pro (14-inch, M2 Pro).jpg'
import featured4 from '../../assest/AayatTelecom/mac/MacBook Pro (16-inch, M2 ProM2 Max).webp'
import featured5 from '../../assest/AayatTelecom/mac/iMac (24-inch, M1).jpeg'
import featured6 from '../../assest/AayatTelecom/mac/Mac Mini (M2, M2 Pro).jpg'
import featured7 from '../../assest/AayatTelecom/mac/Mac Studio (M2 Max M2 Ultra).jpg'
import featured8 from '../../assest/AayatTelecom/mac/Mac Pro (Apple Silicon).jpg'





const AllMacProductData = [
    {
        id: 1,
        companyName:'MacBook Air (15-inch, M2)',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'MacBook Air (13-inch, M2)',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'MacBook Pro (14-inch, M2 Pro/M2 Max)',
        imgSrc: featured3,
    }, 
    {
        id: 4,
        companyName:'MacBook Pro (16-inch, M2 Pro/M2 Max)',
        imgSrc: featured4,
    },
     {
        id: 5,
        companyName:'iMac (24-inch, M1)',
        imgSrc: featured5,
    }, 
    {
        id: 6,
        companyName:'Mac Mini (M2, M2 Pro)',
        imgSrc: featured6,
    },
    {
        id: 7,
        companyName:'Mac Studio (M2 Max/M2 Ultra)',
        imgSrc: featured7,
    }, 
    {
        id: 8,
        companyName:'Mac Pro (Apple Silicon)',
        imgSrc: featured8,
    },
    {
        id: 8,
        companyName:'MacBook Air (13-inch, M2 Pro)',
        imgSrc: featured2,
    },
   
];
export default AllMacProductData