
import featured1 from '../../assest/AayatTelecom/Xiaomi/REDMI A3.webp'
import featured2 from '../../assest/AayatTelecom/Xiaomi/REDMI 13.webp'
import featured3 from '../../assest/AayatTelecom/Xiaomi/REDMI 13 PRO.jpg'
import featured4 from '../../assest/AayatTelecom/Xiaomi/REDMI 14C.jpg'
import featured5 from '../../assest/AayatTelecom/Xiaomi/REDMI 13C.jpg'
import featured6 from '../../assest/AayatTelecom/Xiaomi/POCO C65.jpg'




const AllXiaomiProductData = [
    {
        id: 1,
        companyName:'REDMI A3',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'REDMI 13',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'REDMI 13 PRO',
        imgSrc: featured3,
    }, 
    {
        id: 4,
        companyName:'REDMI 14C',
        imgSrc: featured4,
    },
     {
        id: 5,
        companyName:'REDMI 13C',
        imgSrc: featured5,
    }, 
    {
        id: 6,
        companyName:'POCO C65 ',
        imgSrc: featured6,
    }
   
];
export default AllXiaomiProductData