import polygon from "../../assest/AayatTelecom/iphone/Apple iPhonebanner2.jpg";
import { Box, Button } from "@mui/material";
import "../../styles/AayatTelecom/ServiceStyle.css";
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { useState } from "react";
import MobileCard from "../../components/MobileCard";
import AllProductData from "../../data/AayatTelecom/AllProductData";
import AllIpadProductData from "../../data/AayatTelecom/AllIpadProductData";
import AllMacProductData from "../../data/AayatTelecom/AllMacProductData";
import AllIphoneProductData from "../../data/AayatTelecom/AllIphoneProductData";
import GradeImg1 from "../../assest/AayatTelecom/Grade-A-1.jpeg";
import GradeImg2 from "../../assest/AayatTelecom/Grade-B-1.jpeg";
import GradeImg3 from "../../assest/AayatTelecom/Grade-C-1.jpeg";
import GradeImg4 from "../../assest/AayatTelecom/Grade-D-1.jpeg";
import AllSamsungProductData from "../../data/AayatTelecom/AllSamsungProductData";
import AllXiaomiProductData from "../../data/AayatTelecom/AllXiaomiProductData";
import AllTechnoProductData from "../../data/AayatTelecom/AllTechnoProductData";
import AllInfinixProductData from "../../data/AayatTelecom/AllInfinixProductData";

const mangeTabActive = {
  color: "white",
  borderBottom: "3px solid #f93432",
  backgroundColor: "#f93432",
  borderRadius: "20px 20px 0 0",
};

const Services = () => {
  const [value, setValue] = useState(0);

  const onHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="Aboutus_topdiv">
        <img src={polygon} alt="img" />
        <div className="hosp_aboutushead">
          <h1>Services</h1>
        </div>
      </Box>

      <Box className="featured_projectweworkIn">
        <div className="logistic_topdiv pb-2">
          <h1 className=" fw-bold">
            What Kind Of Product Are You Looking For?
          </h1>
        </div>
        <p className=" text-center mb-5 px-5 pb-5">
          Aayat Telecom FZCO, located in DAFZA, is a fully-owned and operated
          distributor of new iPhones in Dubai. With more than a year of industry
          expertise, our commitment is to offer our customers genuine, top-notch
          products from well-known brands at unmatched prices. Our inventory
          comprises exclusively of phones and ASIS stock.
        </p>

        <Tabs value={value} onChange={onHandleChange} className="tabs_latest">
          <div className="head_tabs">
            <TabsList style={{ borderBottom: "3px solid #f93432" }}>
              <Tab
                style={value === 0 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={0}
              >
                All Products
              </Tab>
              <Tab
                style={value === 1 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={1}
              >
                IPHONE{" "}
              </Tab>
              <Tab
                style={value === 2 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={2}
              >
                SAMSUNG{" "}
              </Tab>
              <Tab
                style={value === 3 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={3}
              >
                XIAOMI{" "}
              </Tab>
              <Tab
                style={value === 4 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={4}
              >
                TECHNO{" "}
              </Tab>
              <Tab
                style={value === 5 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={5}
              >
                INFINIX{" "}
              </Tab>
              <Tab
                style={value === 6 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={6}
              >
                IPAD{" "}
              </Tab>
              {/* <Tab style={value === 7 ? { ...mangeTabActive } : {}} className='mangetab1' value={7}>MAC </Tab> */}
            </TabsList>
          </div>
          <div className="latest_TabPanelDiv">
            <TabPanel value={0}>
              <MobileCard detailsData={AllProductData} />
            </TabPanel>
            <TabPanel value={1}>
              <MobileCard detailsData={AllIphoneProductData} />
            </TabPanel>
            <TabPanel value={2}>
              <MobileCard detailsData={AllSamsungProductData} />
            </TabPanel>
            <TabPanel value={3}>
              <MobileCard detailsData={AllXiaomiProductData} />
            </TabPanel>

            <TabPanel value={4}>
              <MobileCard detailsData={AllTechnoProductData} />
            </TabPanel>

            <TabPanel value={5}>
              <MobileCard detailsData={AllInfinixProductData} />
            </TabPanel>

            <TabPanel value={6}>
              <MobileCard detailsData={AllIpadProductData} />
            </TabPanel>

            <TabPanel value={7}>
              <MobileCard detailsData={AllMacProductData} />
            </TabPanel>
          </div>
        </Tabs>
      </Box>

      {/* <Box className='Boost_about_div'>
                <h1>Grades</h1>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={GradeImg1} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <p className=' fw-bold text-primary d-flex align-items-center text-uppercase fs-6'>Grades <hr className='ms-2 my-0' style={{ width: '30%', border: '1px solid #f93432' }} /></p>
                        <h6 className=''>Grade A</h6>
                        <p className=''>These devices are in pristine condition, like new, with no signs of wear. They come with minimal or no cosmetic defects, making them ideal for customers who demand perfection.</p>
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">

                    <div className="textdiv col-lg-6 col-12">
                        <p className=' fw-bold text-primary d-flex align-items-center text-uppercase fs-6'>Grades <hr className='ms-2 my-0' style={{ width: '30%', border: '1px solid #f93432' }} /></p>
                        <h6 className=''>Grade B</h6>
                        <p className=''>Devices in Grade B condition may have minor cosmetic imperfections but are fully functional and reliable. They offer a balance between quality and affordability.</p>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img src={GradeImg2} alt="img" />
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">

                    <div className="imggdiv col-lg-6 col-12">
                        <img src={GradeImg3} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <p className=' fw-bold text-primary d-flex align-items-center text-uppercase fs-6'>Grades <hr className='ms-2 my-0' style={{ width: '30%', border: '1px solid #f93432' }} /></p>
                        <h6 className=''>Grade C</h6>
                        <p className=''>Grade C devices may have noticeable cosmetic flaws but are still fully functional and offer an economical choice for customers on a budget while D devcies are damaged and need screen or back replacement.</p>
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">

                    <div className="textdiv col-lg-6 col-12">
                        <p className=' fw-bold text-primary d-flex align-items-center text-uppercase fs-6'>Grades <hr className='ms-2 my-0' style={{ width: '30%', border: '1px solid #f93432' }} /></p>
                        <h6 className=''>Grade D</h6>
                        <p className=''>Grade D devices are in the lowest condition category and are typically damaged, Broken back glass but tested stock with report.These devices</p>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img src={GradeImg4} alt="img" />
                    </div>

                </Box>
            </Box> */}
    </>
  );
};

export default Services;
