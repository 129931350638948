
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import ContactUs from "./page/AayatTelecom/ContactUs";
import AboutUs from "./page/AayatTelecom/AboutUs";
import TermsConditions from "./page/AayatTelecom/TermsConditions";
import PrivacyPolicy from "./page/AayatTelecom/PrivacyPolicy";
import VideoGallery from "./page/AayatTelecom/VideoGallery";
import FAQPage from "./page/AayatTelecom/FAQ";
import PhotoGallery from "./page/AayatTelecom/PhotoGallery";
import Blogs from "./page/AayatTelecom/Blogs";
import MainHomeAayat from "./page/AayatTelecom/MainHomeAayat";
import Footer from "./common/AayatTelecom/Footer/Footer";
import Header from "./common/AayatTelecom/Header/Header";
import ScrollToTop from "./components/ScrollToTop";
import ErrorPage from "./page/AayatTelecom/ErrorPage";
import Services from "./page/AayatTelecom/Services";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <BrowserRouter>
        <div className="top-to-btm">
          {showTopBtn && (
            <FaAngleUp
              className="icon-position icon-style "
              style={{ zIndex: 199 }}
              onClick={goToTop}
            />
          )}
        </div>

        <ScrollToTop />
        <Header />

        <Routes>

          {/* aayat telecom fzco */}

          <Route path='/' element={<MainHomeAayat />} />
          <Route path='/contact_us' element={<ContactUs />} />
          <Route path='/terms_conditions' element={<TermsConditions />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/video_gallery' element={<VideoGallery />} />
          <Route path='/FAQ' element={<FAQPage />} />
          <Route path='/photo_gallery' element={<PhotoGallery />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='*' element={<ErrorPage />} />
          <Route path='/about_us' element={<AboutUs />} />
          <Route path='/services' element={<Services />} />



        </Routes>
        <Footer />

      </BrowserRouter>

    </>
  );
}

export default App;
