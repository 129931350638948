

import polygon from '../../assest/AayatTelecom/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/AayatTelecom/AboutUsStyle.css'





const TermsConditions = () => {

    return (
        <>

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Terms & Conditions</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold">Terms & Conditions</p>

                    <p>Welcome to Aayat Telecom! Please take a moment to review our Terms and Conditions carefully. By accessing or using our website and services, you agree to abide by these terms. If you do not agree with any part of these terms, please refrain from using our services.</p>

                    <p className=" fw-bold">Ordering and Minimum Quantity:</p>
                    <ol>
                        <li>Aayat Telecom is a wholesaler of new brand Phones based in the UAE.</li>
                        <li>To place an order, a minimum quantity of 50 pieces is required.</li>
                    </ol>

                    <p className=" fw-bold">Product Information:</p>
                    <ol>
                        <li>We make every effort to accurately display product descriptions, specifications, and pricing. However, errors or inaccuracies may occur. We reserve the right to correct such errors and revise information at any time without prior notice.</li>
                        <li>Product images may not always reflect the exact appearance of the products.</li>
                    </ol>

                    <p className=" fw-bold">Warranty:</p>
                    <ol>
                        <li>All products purchased from Aayat Telecom come with a 2-month warranty, starting from the date of delivery.</li>
                        <li>The warranty covers defects in materials and workmanship. It does not cover damage caused by misuse, accidents, or unauthorized modifications.</li>
                    </ol>
                    <p className=" fw-bold">Shipping:</p>
                    <ol>
                        <li>We offer worldwide shipping services. Shipping costs and estimated delivery times will be provided at the time of order placement.</li>
                        <li>Aayat Telecom may not be responsible for customs duties, taxes, or import fees imposed by the destination country.</li>
                    </ol>
                    <p className=" fw-bold">Returns and Refunds:</p>
                    <ol>
                        <li>If you receive a damaged or defective product, please contact our customer support within 7 days of receiving the order to initiate a return or exchange.</li>
                        <li>To be eligible for a return or exchange, the product must be in its original condition with all accessories and packaging.</li>
                        <li>Refunds will be processed within 14 business days after receiving the returned product.</li>
                    </ol>


                    <p className=" fw-bold">Payment:</p>
                    <ol>
                        <li>We accept various payment methods, including credit cards and electronic payments. Payment information is securely processed through our payment gateway partners.</li>
                    </ol>

                    <p className=" fw-bold">Privacy and Security:</p>
                    <ol>
                        <li>We take your privacy seriously. Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your personal information.</li>
                    </ol>

                    <p className=" fw-bold">Intellectual Property:</p>
                    <ol>
                        <li>All content on our website, including text, images, logos, and trademarks, is the intellectual property of Aayat Telecom and is protected by copyright laws. Unauthorized use of our content is strictly prohibited.</li>
                    </ol>

                    <p className=" fw-bold">Changes to Terms and Conditions:</p>
                    <ol>
                        <li>Aayat Telecom reserves the right to update or modify these Terms and Conditions at any time. Please check this page periodically for changes. Your continued use of our services following any updates constitutes acceptance of the revised terms.</li>
                    </ol>

                    <p className=" fw-bold">Contact Information:</p>
                    <ol>
                        <li>If you have any questions, concerns, or inquiries regarding these Terms and Conditions, please contact us at info@aayattelecomfzco.com.</li>
                        <li>Thank you for choosing Aayat Telecom. We are dedicated to providing you with high-quality iPhones and outstanding service.</li>
                    </ol>

                    <p className=" fw-bold">Need help?</p>

                    <p>Contact us at info@aayattelecomfzco.com for any inquery.</p>

                </div>
            </div>



        </>
    )
}

export default TermsConditions