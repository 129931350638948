import people6 from '../../assest/AayatTelecom/visualhunter.png'
import people7 from '../../assest/AayatTelecom/full-hd.png'
import people8 from '../../assest/AayatTelecom/ipad-1.png'



const AayatTopBannersData = [
    {
        id: 1,
        heading:'WELCOME TO AAYAT TELECOM FZCO',
        textInfo:"Your one-stop shop for all your Apple mobile needs.",
        src: people7,
    },
    {
        id: 2,
        heading:'Your Experience',
        textInfo:'Unlocking Possibilities: Your Mobile Experience Begins Here',
        src: people8,
    }, 
    {
        id: 3,
        heading:'OUR GOAL',
        textInfo:'Revolutionizing the secondary mobile device market, Providing Affordable Mobile Tech for Everyone, Everywhere',
        src: people6,
    }, 
   
];
export default AayatTopBannersData;
