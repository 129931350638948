import "./FooterStyle.css";
import Logo from "../../../assest/AayatTelecom/logo.png";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import { MdCall, MdEmail, MdLocationPin } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <Box className="Talk_news">
        <div className="footertalk_maindiv">
          <div className="heading_talk">
            <h1 className="ps-2 d-flex align-items-center">
              <FaPhone className=" me-2" /> Call Now : +971563488541{" "}
            </h1>
          </div>
        </div>
      </Box>
      <footer className="newfooter">
        <div className="container">
          <div className="footer_links">
            <div className="row">
              <div className="col-md-3">
                <img src={Logo} alt="RestaurantIndia" className="footerlogo" />
                <div className="footer-sub-head fs-6 pe-4">
                  Aayat Telecom FZCO is a dynamic and innovative company
                  specializing in the wholesale distribution of mobile phones.
                </div>
                {/* <a href="/" target="_blank" className="redlnk">Become A Member</a> */}
                <div className="footerlnk-head">Follow Us</div>
                <ul className="soc_ul_footer">
                  <li>
                    <a href="/" target="_blank">
                      <img
                        src="https://www.indianretailer.com/restaurant/themes/menshealth/images/fb.png"
                        alt="Facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <img
                        src="https://www.indianretailer.com/restaurant/themes/menshealth/images/in.png"
                        alt="Instagram"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <img
                        src="https://www.indianretailer.com/restaurant/themes/menshealth/images/li.png"
                        alt="LinkedIn"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <img
                        src="https://www.indianretailer.com/restaurant/themes/menshealth/images/tw.png"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <div className="footerlnk-head">About Company</div>
                <ul className="menu">
                  <li>
                    <Link to="/about_us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/Contact_us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/photo_gallery">Photo Gallery</Link>
                  </li>
                  <li>
                    <Link to="/video_gallery">Video Gallery</Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-3">
                <div className="footerlnk-head">Quick Links</div>
                <ul className="menu">
                  <li>
                    <Link to="/FAQ">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li>
                    <Link to="/privacy_policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms_conditions">Terms & Condition</Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-3">
                <div className="footerlnk-head">Contact Us</div>
                <ul className="menu_div">
                  <li className="fw-bold">AAYAT TELECOM FZCO</li>
                  <li className=" mt-3">
                    <MdLocationPin className=" me-2 fs-3" />
                    Aayat Telecom FZCO Building E3 5th Floor, Office No 513A,
                    DAFZA Dubai Airport Freezone, UAE
                  </li>
                  <li className=" mt-3">
                    <MdCall className=" me-2 fs-3" />
                    <Link to="tel:+971563488541">+971563488541</Link>
                  </li>

                  <li className=" mt-3">
                    <MdEmail className=" me-2 fs-3" />
                    <Link to="mailto:info@aayattelecomfzco.com">
                      info@aayattelecomfzco.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <div className="container">
          <div className="copy">
            <p>Copyright © 2024 Powered By Abaris Softech.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
