

import featured1 from '../../assest/AayatTelecom/Techno/Spark Go.jpg'
import featured2 from '../../assest/AayatTelecom/Techno/Spark Go 1.png'
import featured3 from '../../assest/AayatTelecom/Techno/TECNO POVA 6.png'
import featured4 from '../../assest/AayatTelecom/Techno/TECNO CAMON 20.webp'




const AllTechnoProductData = [
    {
        id: 1,
        companyName:'Spark Go',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'Spark Go 1',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'TECNO POVA 6',
        imgSrc: featured3,
    }, 
    {
        id: 4,
        companyName:'TECNO CAMON 20',
        imgSrc: featured4,
    }
   
];
export default AllTechnoProductData