import GalleryPhotos from "../../common/AayatTelecom/Gallery/Gallery"
import GallerData from "../../data/AayatTelecom/GallerData"
import polygon from '../../assest/AayatTelecom/iphone/Apple iPhonebanner2.jpg'
import { Box, Button } from "@mui/material"

const PhotoGallery = () => {
    return (
        <>

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Photo Gallery</h1>
                </div>
            </Box>
            <GalleryPhotos GalleryData={GallerData} title={"Photo Gallery"} />

        </>
    )
}

export default PhotoGallery