import featured1 from '../../assest/AayatTelecom/Samsung/SAMSUNG A055.jpg'
import featured2 from '../../assest/AayatTelecom/Samsung/SAMSUNG A057.jpg'
import featured3 from '../../assest/AayatTelecom/Samsung/SAMSUNG A155.png'
import featured4 from '../../assest/AayatTelecom/Samsung/SAMSUNG A256.webp'
import featured5 from '../../assest/AayatTelecom/Samsung/SAMSUNG A356.webp'
import featured6 from '../../assest/AayatTelecom/Samsung/SAMSUNG A556.webp'
import featured7 from '../../assest/AayatTelecom/Samsung/GALAXY FOLD 6.png'
import featured8 from '../../assest/AayatTelecom/Samsung/GALAXY FLIP 6.webp'
import featured9 from '../../assest/AayatTelecom/Samsung/SASMUNG S24 ULTRA.webp'



const AllSamsungProductData = [
    {
        id: 1,
        companyName:'SAMSUNG A055',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'SAMSUNG A057',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'SAMSUNG A155',
        imgSrc: featured3,
    }, 
    {
        id: 4,
        companyName:'SAMSUNG A256 ',
        imgSrc: featured4,
    },
     {
        id: 5,
        companyName:'SASMUNG A356',
        imgSrc: featured5,
    }, 
    {
        id: 6,
        companyName:'SAMSUNG A556 ',
        imgSrc: featured6,
    },
    {
        id: 7,
        companyName:'GALAXY FOLD 6 ',
        imgSrc: featured7,
    },
    {
        id: 8,
        companyName:'GALAXY FLIP 6 ',
        imgSrc: featured8,
    },
    {
        id: 9,
        companyName:'SASMUNG S24 ULTRA ',
        imgSrc: featured9,
    },
   
];
export default AllSamsungProductData