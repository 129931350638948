import client1 from "../../assest/AayatTelecom/xiaomi-brand-logo.jpg";
import client2 from "../../assest/AayatTelecom/dyson logo.png";
import client3 from "../../assest/AayatTelecom/apple logo.webp";
import client4 from "../../assest/AayatTelecom/samsunglogo.png";
import client5 from "../../assest/AayatTelecom/gsm.jpeg";
import client6 from "../../assest/AayatTelecom/ps5-playstation.png";

const BrandsWeDealInData = [
  {
    id: 1,
    imgSrc: client1,
    // name: 'Chennai'
  },
  {
    id: 2,
    imgSrc: client2,
    // name: 'Delhi'
  },
  {
    id: 3,
    imgSrc: client3,
    // name: 'Kochi'
  },
  {
    id: 4,
    imgSrc: client4,
    // name: 'NHAI'
  },
  
  {
    id: 6,
    imgSrc: client6,
    // name: 'Chennai'
  },
];
export default BrandsWeDealInData;
