import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

// import 'react-pro-sidebar/dist/css/styles.css';
import "./sideBar.css"
import { MdEventNote, MdOutlineQuestionAnswer } from "react-icons/md";
import { GiIndiaGate, GiWorld } from "react-icons/gi";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GiSkills } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";
import { IoMdPhotos } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { MdTour } from "react-icons/md";

const SideBar = () => {


  return (
    <>
      <div id="main-div">
        {/* <ProSidebar
          style={{ height: "100%", position: "absolute" }}
        > */}
          <div className="sidebar-body-div">
            {/* <SidebarContent className="main-div"> */}
              <Menu className="sidebar-content-div"
                iconShape="circle"
              >
                <p className="Menus-p">MENUS</p>

                <MenuItem icon={<GoHome />}> Home <Link to={'/'} /></MenuItem>

                <MenuItem icon={<FaUser />}> About Us <Link to={'/about_us'} /></MenuItem>

                <MenuItem icon={<MdTour />}> Services <Link to={'/services'} /></MenuItem>

                <MenuItem icon={<IoMdPhotos />}>Photo Gallery <Link to={'/photo_gallery'} /></MenuItem>

                <MenuItem icon={<MdOutlineQuestionAnswer />}>FAQs<Link to={'/FAQ'} /></MenuItem>

                <MenuItem icon={<CgWebsite />}>Blog<Link to={'/blogs'} /></MenuItem>

                <MenuItem icon={<BiMessageSquareDetail />}>Contact Us<Link to={'/contact_us'} /></MenuItem>


              </Menu>
            {/* </SidebarContent> */}
          </div>
        {/* </ProSidebar> */}
      </div>
    </>
  );
};

export default SideBar;

