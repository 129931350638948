import polygon from '../../assest/AayatTelecom/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/AayatTelecom/AboutUsStyle.css'


const PrivacyPolicy = () => {

    return (
        <>

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Privacy Policy</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold">Privacy Policy</p>

                    <p>At Aayat Telecom FZCO, accessible from http://aayattelecomfzco.com, your privacy is our utmost concern. This Privacy Policy explains the types of information we collect and how we utilize it.</p>
                    <p>If you have any questions or require further information about our Privacy Policy, please feel free to contact us.</p>
                    <p>This Privacy Policy pertains solely to our online activities and is applicable to visitors to our website concerning the information they share and collect through Aayat Telecom FZCO. This policy does not apply to any data collected offline or via channels other than this website.</p>
                    <p className=" fw-bold">Consent</p>
                    <p>By using our website, you consent to our Privacy Policy and agree to its terms.</p>
                    <p className=" fw-bold">Information we collect</p>
                    <p>We collect and use personal information as required for specific purposes. When you provide personal information, we will clarify the reasons for its collection.</p>
                    <p>If you contact us directly, we may collect additional information such as your name, email address, phone number, the contents of your message, and any attachments you send us.</p>
                    <p>When you register for an account, we may request contact information, including your name, company name, address, email address, and telephone number.</p>


                    <p className=" fw-bold">How we use your information</p>
                    <p>We employ the collected information for various purposes, including:</p>

                    <ul>
                        <li>Operating and maintaining our website</li>
                        <li>Enhancing and personalizing our website</li>
                        <li>Analyzing your website usage</li>
                        <li>Developing new products, services, features, and functionality</li>
                        <li>Communicating with you directly or through our partners for customer service, updates, and marketing</li>
                        <li>Sending emails</li>
                        <li>Detecting and preventing fraud</li>
                    </ul>

                    <p className=" fw-bold">Log Files</p>
                    <p>Aayat Telecom FZCO follows standard log file procedures. These files record visitors’ activities on our website. The information includes IP addresses, browser types, Internet Service Providers (ISPs), date and time stamps, referring/exit pages, and the number of clicks. This data is not linked to personally identifiable information. We use this information to analyze trends, administer the site, track user movement, and gather demographic data.</p>

                    <p className=" fw-bold">Cookies and Web Beacons</p>
                    <p>Similar to other websites, Aayat Telecom FZCO uses cookies to store visitor preferences and track pages accessed by visitors. This information helps us optimize the user experience by tailoring web page content based on browser type and other data.</p>

                    <p className=" fw-bold">Advertising Partners Privacy Policies</p>
                    <p>For privacy policies related to advertising partners, please refer to the respective list on Aayat Telecom FZCO.</p>
                    <p>Third-party ad servers or networks may use technologies like cookies, JavaScript, or web beacons in their advertisements and links on Aayat Telecom FZCO. These technologies collect your IP address when you interact with their ads. They use this data to measure the effectiveness of their advertising campaigns and personalize content on visited websites. Aayat Telecom FZCO does not have access to or control over these cookies used by third-party advertisers.</p>

                    <p className=" fw-bold">Third-Party Privacy Policies</p>
                    <p>Aayat Telecom FZCO’s Privacy Policy does not cover other advertisers or websites. We recommend consulting the respective Privacy Policies of third-party ad servers for detailed information, including practices and opt-out instructions.</p>
                    <p>You can manage cookies through your browser settings. For more information about cookie management with specific web browsers, visit the respective browser websites.</p>


                    <p className=" fw-bold">CCPA Privacy Rights (Do Not Sell My Personal Information)</p>
                    <p>Under the CCPA, California consumers have specific rights:</p>

                    <ol>
                        <li>Request disclosure of categories and specific pieces of personal data collected</li>
                        <li>Request the deletion of personal data collected</li>
                        <li>Request that personal data not be sold</li>
                    </ol>

                    <p>If you exercise any of these rights, we will respond within one month. To do so, please contact us.</p>

                    <p className=" fw-bold">GDPR Data Protection Rights</p>
                    <p>We want you to be aware of your data protection rights. Every user is entitled to the following:</p>
                    <ul>
                        <li>The right to access personal data and request copies</li>
                        <li>The right to rectify inaccurate information and complete incomplete data</li>
                        <li>The right to erasure under certain conditions</li>
                        <li>The right to restrict processing under certain conditions</li>
                        <li>The right to object to processing under certain conditions</li>
                        <li>The right to data portability under certain conditions</li>
                    </ul>

                    <p>We will respond to your request within one month. To exercise any of these rights, please contact us.</p>




                    <p className=" fw-bold">Children’s Information</p>
                    <p>We prioritize children’s online protection and urge parents and guardians to participate in, monitor, and guide their online activities.</p>
                    <p>Aayat Telecom FZCO does not knowingly collect personal identifiable information from children under 13. If you believe a child has provided such information on our website, please contact us immediately. We will promptly remove the information from our records.</p>


                    <p className=" fw-bold">Changes to This Privacy Policy</p>
                    <p>We may periodically update our Privacy Policy. We advise reviewing this page for any changes. We will notify you of changes by posting the new Privacy Policy on this page. These changes take effect immediately upon posting.</p>

                    <p className=" fw-bold">Contact Us</p>
                    <p>For any questions or suggestions about our Privacy Policy, please do not hesitate to contact us (info@aayattelecomfzco.com).</p>


                </div>
            </div>



        </>
    )
}

export default PrivacyPolicy