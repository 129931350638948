
import featured1 from '../../assest/AayatTelecom/iphone/iPhone_16_Pro.jpg'
import featured2 from '../../assest/AayatTelecom/Samsung/GALAXY FLIP 6.webp'
import featured3 from '../../assest/AayatTelecom/iphone/iphone16.jpeg'
import featured4 from '../../assest/AayatTelecom/Xiaomi/REDMI 14C.jpg'
import featured5 from '../../assest/AayatTelecom/Infinix/HOT 40 PRO.webp'
import featured6 from '../../assest/AayatTelecom/Samsung/SAMSUNG A057.jpg'
import featured7 from '../../assest/AayatTelecom/Techno/TECNO CAMON 20.webp'
import featured8 from '../../assest/AayatTelecom/Infinix/NOTE 40X 5G.jpg'
import featured9 from '../../assest/AayatTelecom/Samsung/SAMSUNG A155.png'
import featured10 from '../../assest/AayatTelecom/Samsung/SAMSUNG A055.jpg'
import featured11 from '../../assest/AayatTelecom/Techno/Spark Go 1.png'
import featured12 from '../../assest/AayatTelecom/Samsung/SAMSUNG A256.webp'
import featured13 from '../../assest/AayatTelecom/iphone/IPHONE16promax.webp'
import featured14 from '../../assest/AayatTelecom/Samsung/SAMSUNG A356.webp'
import featured15 from '../../assest/AayatTelecom/iphone/iphone 15.png'
import featured16 from '../../assest/AayatTelecom/Xiaomi/REDMI 13C.jpg'
import featured17 from '../../assest/AayatTelecom/Samsung/SAMSUNG A556.webp'
import featured18 from '../../assest/AayatTelecom/iphone/iphone-15-pro-gray.jpg'
import featured19 from '../../assest/AayatTelecom/Infinix/SMART 8 PRO.jpg'
import featured20 from '../../assest/AayatTelecom/Samsung/GALAXY FOLD 6.png'
import featured21 from '../../assest/AayatTelecom/Xiaomi/REDMI A3.webp'
import featured22 from '../../assest/AayatTelecom/iphone/IPHONE15promax.jpg'
import featured23 from '../../assest/AayatTelecom/Xiaomi/POCO C65.jpg'
import featured24 from '../../assest/AayatTelecom/Techno/Spark Go.jpg'
import featured25 from '../../assest/AayatTelecom/Xiaomi/REDMI 13 PRO.jpg'
import featured26 from '../../assest/AayatTelecom/Techno/TECNO POVA 6.png'
import featured27 from '../../assest/AayatTelecom/Infinix/NOTE 40.png'
import featured28 from '../../assest/AayatTelecom/Samsung/SASMUNG S24 ULTRA.webp'
import featured29 from '../../assest/AayatTelecom/Xiaomi/REDMI 13.webp'
import featured30 from '../../assest/AayatTelecom/Infinix/HOT 40I.jpg'
import featured31 from '../../assest/AayatTelecom/Infinix/SMART 8.jpg'






const AllProductData = [

    {
        id: 1,
        companyName: 'IPHONE 16 PRO',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName: 'GALAXY FLIP 6 ',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName: 'IPHONE 16',
        imgSrc: featured3,
    },
    {
        id: 4,
        companyName: 'REDMI 14C',
        imgSrc: featured4,
    },
    {
        id: 5,
        companyName: 'HOT 40 PRO',
        imgSrc: featured5,
    },
    {
        id: 6,
        companyName: 'SAMSUNG A057',
        imgSrc: featured6,
    },
    {
        id: 7,
        companyName: 'TECNO CAMON 20',
        imgSrc: featured7,
    },
    {
        id: 8,
        companyName: 'NOTE 40X 5G',
        imgSrc: featured8,
    },
    {
        id: 9,
        companyName: 'SAMSUNG A155',
        imgSrc: featured9,
    },
    {
        id: 10,
        companyName: 'SAMSUNG A055',
        imgSrc: featured10,
    },
    {
        id: 11,
        companyName: 'Spark Go 1',
        imgSrc: featured11,
    },

    {
        id: 12,
        companyName: 'SAMSUNG A256 ',
        imgSrc: featured12,
    },
    {
        id: 13,
        companyName: 'IPHONE 16 PRO MAX',
        imgSrc: featured13,
    },
    {
        id: 14,
        companyName: 'SASMUNG A356',
        imgSrc: featured14,
    },
    {
        id: 15,
        companyName: 'IPHONE 15',
        imgSrc: featured15,
    },
    {
        id: 16,
        companyName: 'REDMI 13C',
        imgSrc: featured16,
    },
    {
        id: 17,
        companyName: 'SAMSUNG A556 ',
        imgSrc: featured17,
    },
    {
        id: 18,
        companyName: 'IPHONE 15 PRO',
        imgSrc: featured18,
    },
    {
        id: 19,
        companyName: 'SMART 8 PRO ',
        imgSrc: featured19,
    },
    {
        id: 20,
        companyName: 'GALAXY FOLD 6 ',
        imgSrc: featured20,
    },
    {
        id: 21,
        companyName: 'REDMI A3',
        imgSrc: featured21,
    },

    {
        id: 22,
        companyName: 'IPHONE 15 PRO MAX ',
        imgSrc: featured22,
    },
    {
        id: 23,
        companyName: 'POCO C65 ',
        imgSrc: featured23,
    },
    {
        id: 24,
        companyName: 'Spark Go',
        imgSrc: featured24,
    },
    {
        id: 25,
        companyName: 'REDMI 13 PRO',
        imgSrc: featured25,
    },
    {
        id: 26,
        companyName: 'TECNO POVA 6',
        imgSrc: featured26,
    },
    {
        id: 27,
        companyName: 'NOTE 40',
        imgSrc: featured27,
    },
    {
        id: 28,
        companyName: 'SASMUNG S24 ULTRA ',
        imgSrc: featured28,
    },
    {
        id: 29,
        companyName: 'REDMI 13',
        imgSrc: featured29,
    },
    {
        id: 30,
        companyName: 'HOT 40I',
        imgSrc: featured30,
    },
    {
        id: 31,
        companyName: 'SMART 8',
        imgSrc: featured31,
    },


];
export default AllProductData