import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Box, Button } from "@mui/material";
import "../../styles/AayatTelecom/MainHomeAayatStyle.css";
import img1 from "../../assest/AayatTelecom/iphone/iphone-15-pro-in-front-apple-store.jpg";
import img5 from "../../assest/AayatTelecom/Apple-iPad-19.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ThankYouDialoge from "../../components/ThankYouDialoge";
import AayatTopBannersData from "../../data/AayatTelecom/AayatTopBannersData";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import MobileCard from "../../components/MobileCard";
import AllProductData from "../../data/AayatTelecom/AllProductData";
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import img4 from "../../assest/AayatTelecom/cellular-phone.png";
import number1 from "../../assest/AayatTelecom/number-1.png";
import number2 from "../../assest/AayatTelecom/number-2.png";
import number3 from "../../assest/AayatTelecom/number-3.png";
import number4 from "../../assest/AayatTelecom/number-4.png";
import AllIphoneProductData from "../../data/AayatTelecom/AllIphoneProductData";
import AllIpadProductData from "../../data/AayatTelecom/AllIpadProductData";
import AllMacProductData from "../../data/AayatTelecom/AllMacProductData";
import AllSamsungProductData from "../../data/AayatTelecom/AllSamsungProductData";
import AllXiaomiProductData from "../../data/AayatTelecom/AllXiaomiProductData";
import AllTechnoProductData from "../../data/AayatTelecom/AllTechnoProductData";
import AllInfinixProductData from "../../data/AayatTelecom/AllInfinixProductData";
import BrandsWeDealInData from "../../data/AayatTelecom/BrandsWeDealInData";

const mangeTabActive = {
  color: "white",
  borderBottom: "3px solid #f93432",
  backgroundColor: "#f93432",
  borderRadius: "20px 20px 0 0",
};

const MainHomeAayat = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const onHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [EnquiryData, SetEnquiryData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [bordrColor, setBordrColor] = useState("");

  const navigate = useNavigate();

  const onInputChnge = (e) => {
    const inpName = e.target.name;
    const inpVal = e.target.value;
    const cloned = { ...EnquiryData };
    cloned[inpName] = inpVal;
    SetEnquiryData(cloned);
  };

  const onSendClick = async (e) => {
    e.preventDefault();

    try {
      // Reset errors and color
      setErrorName("");
      setErrorEmail("");
      setErrorMobile("");
      setBordrColor("");

      // Perform input validation here before making the API call
      if (
        EnquiryData.name.length > 2 &&
        EnquiryData.email.includes("@") &&
        EnquiryData.mobile.length === 10 &&
        EnquiryData.subject.length > 2 &&
        EnquiryData.message.length > 2
      ) {
        setBordrColor("1px solid green");
        const sign_up = await axios.post(
          "https://backoffice.adamclick.com/api/auth/lead/register",
          { ...EnquiryData }
        );
        SetEnquiryData(sign_up.data);
        console.log(sign_up.data);

        // Clear the form fields
        SetEnquiryData({
          name: "",
          email: "",
          mobile: "",
          subject: "",
          message: "",
        });

        setOpen(true);
        // Provide user feedback or navigation here if needed
        // navigate('/contact')
        console.log("Form data submitted successfully!");
      } else {
        if (EnquiryData.name.length <= 2 || EnquiryData.name === "")
          setErrorName("enter name");
        if (!EnquiryData.email.includes("@") || EnquiryData.email === "")
          setErrorEmail("enter email");
        if (EnquiryData.mobile.length !== 10 || EnquiryData.mobile === "")
          setErrorMobile("enter valid 10 digit number");

        setBordrColor("1px solid white");
        // Provide user feedback for validation errors
        console.log("Form validation failed");
      }
    } catch (error) {
      // Provide user-friendly error message
      console.log("Error submitting form:", error.message);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "1px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "1px",
        },
      },
      {
        breakpoint: 9999, // A large value to ensure the last breakpoint always applies
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  return (
    <>
      <Box id="BoxId">
        <Swiper
          centeredSlides={true}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className=""
          style={{ zIndex: -1 }}
        >
          {AayatTopBannersData?.map(({ id, heading, textInfo, src }) => {
            return (
              <SwiperSlide key={id} className="">
                <div className="row">
                  <div className=" col-lg-6 col-12 customImgDiv">
                    <img src={src} alt="img" />
                  </div>
                  <div className=" col-lg-6 col-12 textdivbox">
                    <h1>{heading}</h1>
                    <p>{textInfo}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>

      <Box className="Boost_about_div">
        <h1 className=" py-5">ABOUT OUR COMPANY</h1>
        <Box className="Boost_about_div_home row">
          <div className="imggdiv col-lg-6 col-12">
            <img src={img1} alt="img" />
          </div>
          <div className="textdiv col-lg-6 col-12">
            <h6>Grow your business with our Company</h6>
            <p>
              Aayat Telecom FZCO is a dynamic and innovative company
              specializing in the wholesale distribution of mobile phones.
              Founded in 2024, we have quickly established a strong reputation
              in the mobile phone industry. Our global customer base is served
              from our headquarters in the UAE. We are dedicated to implementing
              stringent quality assessments at every stage of our procurement
              and distribution processes to ensure that our customers receive
              top-notch mobile devices.
            </p>
            <Link to={"/about_us"}>
              <Button className="morebtn">
                Read More{" "}
                <MdOutlineKeyboardDoubleArrowRight className=" ms-2 fs-4" />
              </Button>
            </Link>
          </div>
        </Box>
      </Box>

      <Box className="featured_projectweworkIn">
        <div className="logistic_topdiv">
          <h1 className=" fw-bold">Explore All Products</h1>
        </div>

        <Tabs value={value} onChange={onHandleChange} className="tabs_latest">
          <div className="head_tabs">
            <TabsList style={{ borderBottom: "3px solid #f93432" }}>
              <Tab
                style={value === 0 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={0}
              >
                All Products
              </Tab>
              <Tab
                style={value === 1 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={1}
              >
                IPHONE{" "}
              </Tab>
              <Tab
                style={value === 2 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={2}
              >
                SAMSUNG{" "}
              </Tab>
              <Tab
                style={value === 3 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={3}
              >
                XIAOMI{" "}
              </Tab>
              <Tab
                style={value === 4 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={4}
              >
                TECHNO{" "}
              </Tab>
              <Tab
                style={value === 5 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={5}
              >
                INFINIX{" "}
              </Tab>
              <Tab
                style={value === 6 ? { ...mangeTabActive } : {}}
                className="mangetab1"
                value={6}
              >
                IPAD{" "}
              </Tab>
              {/* <Tab style={value === 7 ? { ...mangeTabActive } : {}} className='mangetab1' value={7}>MAC </Tab> */}
            </TabsList>
          </div>
          <div className="latest_TabPanelDiv">
            <TabPanel value={0}>
              <MobileCard detailsData={AllProductData} />
            </TabPanel>
            <TabPanel value={1}>
              <MobileCard detailsData={AllIphoneProductData} />
            </TabPanel>
            <TabPanel value={2}>
              <MobileCard detailsData={AllSamsungProductData} />
            </TabPanel>
            <TabPanel value={3}>
              <MobileCard detailsData={AllXiaomiProductData} />
            </TabPanel>

            <TabPanel value={4}>
              <MobileCard detailsData={AllTechnoProductData} />
            </TabPanel>

            <TabPanel value={5}>
              <MobileCard detailsData={AllInfinixProductData} />
            </TabPanel>

            <TabPanel value={6}>
              <MobileCard detailsData={AllIpadProductData} />
            </TabPanel>

            <TabPanel value={7}>
              <MobileCard detailsData={AllMacProductData} />
            </TabPanel>
          </div>
        </Tabs>
      </Box>

      {/* <div className="Sustainability_div">
                <h1 className=" fw-bold py-3">WHAT WE DO</h1>
                <p className="px-5 mx-5">We specialize in providing a diverse array of mobile tech solutions to cater to the needs of a wide-ranging customer base, spanning retailers, e-commerce businesses, wholesalers, and individual consumers</p>

                <div className="Sustainability_slidediv">
                    <div className="Sustainability_div_slide">
                        <img src={img4} alt={`img`} />
                        <h4>New Phones:</h4>
                        <p>We offer the latest models from top brands like Apple and Samsung, complete with warranties for customer peace of mind.</p>
                    </div>

                    <div className="Sustainability_div_slide">
                        <img src={img4} alt={`img`} />
                        <h4>Pre-Owned Phones:</h4>
                        <p>Our certified pre-owned phones provide a reliable and cost-effective alternative to new devices, making quality technology accessible to a wider audience. We offer a diverse selection across brands and models.</p>
                    </div>

                </div>

            </div> */}

      <Box className="Boost_about_div">
        <Box className="Boost_about_div_home row">
          <div className="textdiv col-lg-6 col-12">
            <h6>Why Aayat Telecom FZCO Mobile? Here's why:</h6>
            <p>
              {" "}
              <img src={number1} alt="img" /> Experienced professionals who
              understand your business
            </p>
            <p>
              {" "}
              <img src={number2} alt="img" /> Customized solutions that meet
              your unique requirements
            </p>
            <p>
              {" "}
              <img src={number3} alt="img" /> State-of-the-art technology and
              advanced checking systems
            </p>
            <p>
              {" "}
              <img src={number4} alt="img" /> Commitment to customer
              satisfaction
            </p>
            <p>
              Contact us today to learn more about how we can help you
              streamline your supply chain and take your business to the next
              level.
            </p>
            <Link to={"/contact_us"}>
              <Button className="morebtn">
                CONTACT US{" "}
                <MdOutlineKeyboardDoubleArrowRight className=" ms-2 fs-4" />
              </Button>
            </Link>
          </div>
          <div className="imggdiv col-lg-6 col-12">
            <img src={img5} alt="img" />
          </div>
        </Box>
      </Box>

      <Box className="logistic_clients">
        <h1>Brands We Deal In</h1>
        <div className="our_partner">
          <Slider {...settings}>
            {BrandsWeDealInData?.map((item, index) => (
              <div key={item.id} className="Projects_topimg">
                <img src={item.imgSrc} alt={`img-${item.id}`} />
              </div>
            ))}
          </Slider>
        </div>
      </Box>

      <div className="">
        <section className="contact-us section1">
          <h1 className=" text-center fw-bold text-white pt-5">
            Get connected with us
          </h1>
          <div className="">
            <div className="inner">
              <div className="row justify-content-center py-5">
                <div
                  className="col-lg-6 card-color shadow"
                  style={{
                    background: "",
                    borderRadius: "5px 0 0 5px",
                    border: "1px solid white",
                  }}
                >
                  <div className="contact-us-form">
                    <h5 className=" text-white">
                      If you have any questions please fell free to contact with
                      us.
                    </h5>
                    <form className="form">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              onChange={onInputChnge}
                              value={EnquiryData.name}
                              style={{
                                border: bordrColor,
                                borderRadius: "4px",
                              }}
                              placeholder="Name"
                              required=""
                            />
                            <p
                              style={{ color: "red", fontSize: "12px" }}
                              className="mb-0"
                            >
                              {errorName}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              onChange={onInputChnge}
                              value={EnquiryData.email}
                              style={{
                                border: bordrColor,
                                borderRadius: "4px",
                              }}
                              placeholder="Email"
                              required=""
                            />
                            <p
                              style={{ color: "red", fontSize: "12px" }}
                              className="mb-0"
                            >
                              {errorEmail}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="mobile"
                              onChange={onInputChnge}
                              value={EnquiryData.mobile}
                              style={{
                                border: bordrColor,
                                borderRadius: "4px",
                              }}
                              placeholder="Phone"
                              required=""
                            />
                            <p
                              style={{ color: "red", fontSize: "12px" }}
                              className="mb-0"
                            >
                              {errorMobile}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="subject"
                              onChange={onInputChnge}
                              value={EnquiryData.subject}
                              placeholder="Subject"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              onChange={onInputChnge}
                              value={EnquiryData.message}
                              placeholder="Your Message"
                              required=""
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <Button
                              fullWidth
                              onClick={onSendClick}
                              className="btnbtnbgprimary"
                              type="submit"
                            >
                              Send
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  className=" col-lg-6 col-12 p-1"
                  style={{
                    background: "",
                    borderRadius: "0 5px 5px 0",
                    border: "1px solid white",
                  }}
                >
                  <iframe
                    className=""
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.3216141709554!2d55.37097907359869!3d25.259764329166966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5db3dcc76253%3A0x5c49d305974bca7f!2sDAFZA%20Building%202E%20-%20Smartdesk!5e0!3m2!1sen!2sin!4v1729582575712!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ThankYouDialoge
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default MainHomeAayat;
