import featured1 from '../../assest/AayatTelecom/Infinix/HOT 40I.jpg'
import featured2 from '../../assest/AayatTelecom/Infinix/SMART 8.jpg'
import featured3 from '../../assest/AayatTelecom/Infinix/SMART 8 PRO.jpg'
import featured4 from '../../assest/AayatTelecom/Infinix/NOTE 40.png'
import featured5 from '../../assest/AayatTelecom/Infinix/NOTE 40X 5G.jpg'
import featured6 from '../../assest/AayatTelecom/Infinix/HOT 40 PRO.webp'




const AllInfinixProductData = [
    {
        id: 1,
        companyName:'HOT 40I',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'SMART 8',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'SMART 8 PRO ',
        imgSrc: featured3,
    }, 
    {
        id: 4,
        companyName:'NOTE 40',
        imgSrc: featured4,
    },
    {
        id: 5,
        companyName:'NOTE 40X 5G',
        imgSrc: featured5,
    }, 
    {
        id: 6,
        companyName:'HOT 40 PRO',
        imgSrc: featured6,
    }
   
   
];
export default AllInfinixProductData