

import featured1 from '../../assest/AayatTelecom/ipad/iPad10th generation.jpg'
import featured2 from '../../assest/AayatTelecom/ipad/iPad Air 5th generation.png'
import featured3 from '../../assest/AayatTelecom/ipad/iPad Pro 6th generation.jpg'
import featured4 from '../../assest/AayatTelecom/ipad/iPad Mini 6th generation.jpg'
import featured5 from '../../assest/AayatTelecom/ipad/iPad (9th generation).png'
import featured6 from '../../assest/AayatTelecom/ipad/iPad Pro (5th generation).png'





const AllIpadProductData = [
    {
        id: 1,
        companyName:'iPad (10th generation)',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'iPad Air (5th generation)',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'iPad Pro (6th generation)',
        imgSrc: featured3,
    }, 
    {
        id: 4,
        companyName:'iPad Mini (6th generation) ',
        imgSrc: featured4,
    },
     {
        id: 5,
        companyName:'iPad (9th generation)',
        imgSrc: featured5,
    }, 
    {
        id: 6,
        companyName:'iPad Pro (5th generation) ',
        imgSrc: featured6,
    },
   
];
export default AllIpadProductData